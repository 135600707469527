import React from "react";

import classes from "./Page404.module.css";
import Header from "../components/Header/Header";
import { IoSad } from "react-icons/io5";
import { TiArrowBack } from "react-icons/ti";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <>
      <Header />
      <div className={classes.container}>
        <div className={classes.containerText}>
          <IoSad className={classes.icon} />
          <h1>404</h1>
          <h2>Page not found</h2>

          <Link to="/" className={classes.containerButton}>
            <TiArrowBack className={classes.iconBack} />
            <p>Home</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Page404;
