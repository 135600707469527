import React from "react";
import { useNavigate } from "react-router-dom";

import Header from "../components/Header/Header";
import ChatBot from "../components/ChatBot/ChatBot";
import classes from "./Home.module.css";
import { IoMdAddCircle } from "react-icons/io";
const Home = () => {
  const navigate = useNavigate();
  const addNewHandler = () => navigate("create_new_page");

  return (
    <>
      <Header />
      <ChatBot />
      <div className={classes.iconContainer}>
        <h1>Kreiraj novu stranicu</h1>
        <IoMdAddCircle className={classes.addIcon} onClick={addNewHandler} />
      </div>
    </>
  );
};

export default Home;
