import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialState = {
  contents: 0,
  currentPage: "home",
  forceUpdate: false,
  chatbotInbox: false,
  chatbotBubble: null,
  chatbotMessages: [],
  messageNextId: 0,
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setContents(state, action) {
      state.contents = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    nextPage(state) {
      if (state.currentPage !== "home") state.currentPage++;
      else state.currentPage = 0;
    },
    prevPage(state) {
      if (state.currentPage !== 0) state.currentPage--;
      else state.currentPage = "home";
    },
    forceUpdate(state, action) {
      state.forceUpdate = action.payload;
    },
    chatbotInbox(state, action) {
      state.chatbotInbox = action.payload;
    },
    chatbotBubble(state, action) {
      state.chatbotBubble = action.payload;
    },
    addChatbotMessage(state, action) {
      state.chatbotMessages.push({
        id: state.messageNextId,
        sqlId: action.payload.sqlId,
        from: action.payload.from,
        message: action.payload.message,
        reaction: action.payload.reaction,
      });
      state.messageNextId++;
    },
    addReaction(state, action) {
      state.chatbotMessages[action.payload.id].reaction =
        action.payload.reaction;
    },
  },
});

const store = configureStore({
  reducer: contentSlice.reducer,
});

export const contentActions = contentSlice.actions;

export default store;
