import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { contentActions } from "../../store";
import classes from "./ChatBot.module.css";
import ChatBotIcon from "../../assets/chatbot 250x400.png";
import ChatBotIconHead from "../../assets/chatbot head 150x150.png";
import { FiSend } from "react-icons/fi";
import { IoCloseSharp } from "react-icons/io5";
import { RiRadioButtonLine } from "react-icons/ri";
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import ThreeDotsSpinner from "../UI/Spinners/ThreeDotsSpinner";

import parse from "html-react-parser";

const ChatBot = () => {
  const dispatch = useDispatch();
  const chatbotInbox = useSelector((state) => state.chatbotInbox);
  const chatbotBubble = useSelector((state) => state.chatbotBubble);
  const chatbotMessages = useSelector((state) => state.chatbotMessages);
  const [enteredQuestion, setEnteredQuestion] = useState("");
  const [questionError, setQuestionError] = useState(false);
  const [answering, setAnswering] = useState(false);
  const messagesRef = useRef(null);

  const showBubbleMessage = (type) => {
    if (type === true) {
      dispatch(contentActions.chatbotBubble(type));
      setTimeout(showBubbleMessage, 4000, false);
    } else if (type === false) {
      dispatch(contentActions.chatbotBubble(false));
    }
  };

  if (chatbotBubble === null) setTimeout(showBubbleMessage, 10000, true);

  const botFirstMessage = () => {
    dispatch(
      contentActions.addChatbotMessage({
        from: "bot",
        message: "Pozdrav! 👋\nKako Vam mogu pomoći?",
        reaction: undefined,
      })
    );
  };

  const chatbotIconClickHandler = () => {
    dispatch(contentActions.chatbotInbox(true));
    if (chatbotMessages.length === 0) setTimeout(botFirstMessage, 600);
  };

  const chatbotCloseHandler = () => {
    dispatch(contentActions.chatbotInbox(false));
  };

  const questionChangeHandler = (e) => {
    if (e.target.value.trim().length < 1) setQuestionError(true);
    else setQuestionError(false);

    setEnteredQuestion(e.target.value);
  };

  const submitMessageHandler = async () => {
    if (answering) return;
    if (enteredQuestion.trim().length < 1) return setQuestionError(true);

    dispatch(
      contentActions.addChatbotMessage({
        from: "user",
        message: enteredQuestion,
        reaction: undefined,
        sqlId: undefined,
      })
    );
    setEnteredQuestion("");

    try {
      //setTimeout(() => setAnswering(true), 250);
      setAnswering(true);

      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URI}/chatbot`,
        {
          question: enteredQuestion,
        }
      );
      dispatch(
        contentActions.addChatbotMessage({
          from: "bot",
          message: res.data[0],
          reaction: -1,
          sqlId: parseInt(res.data[1]),
        })
      );
      setAnswering(false);
    } catch (err) {
      dispatch(
        contentActions.addChatbotMessage({
          from: "bot",
          message:
            "<strong className='error'>Došlo je do greške!</strong>\nŽao mi je, zbog greške na serveru nisam u mogućnosti odgovoriti na Vaše pitanje.",
          reaction: undefined,
          sqlId: undefined,
        })
      );
      setAnswering(false);
      console.log(err);
    }
  };

  const questionKeyDownHandler = (e) => {
    if (e.keyCode === 13) submitMessageHandler();
  };

  const reactionHandler = async (id, sqlId, reaction) => {
    if (chatbotMessages[id].reaction !== -1) return;

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URI}/chatbot/${sqlId}`, {
        reaction,
      });
      dispatch(
        contentActions.addReaction({
          id,
          reaction,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  // 👇️ scroll to bottom
  useEffect(() => {
    if (messagesRef.current !== null)
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
  }, [chatbotMessages, chatbotInbox, answering]);

  return (
    <>
      {!chatbotInbox ? (
        <div className={classes.chatbotIcon}>
          {chatbotBubble === true && (
            <p className={classes.chatbotIconMessage}>
              Da li Vam je{"\n"}potrebna pomoć?
            </p>
          )}

          <img
            src={ChatBotIcon}
            alt="chatbot"
            onClick={chatbotIconClickHandler}
          />
        </div>
      ) : (
        <div className={classes.inboxContainer}>
          <div className={classes.inboxHeader}>
            <div className={classes.leftContainer}>
              <img
                src={ChatBotIconHead}
                alt="chatbot"
                onClick={chatbotIconClickHandler}
              />
              <div className={classes.titleContainer}>
                <p>ChatBot</p>
                <RiRadioButtonLine className={classes.onlineIndicator} />
              </div>
            </div>
            <IoCloseSharp
              className={classes.closeButton}
              onClick={chatbotCloseHandler}
            />
          </div>

          <div className={classes.messagesContainer} ref={messagesRef}>
            {chatbotMessages.map((message) => (
              <span
                key={message.id}
                className={message.from === "user" ? classes.user : classes.bot}
              >
                {parse(message.message)}

                {message.from === "bot" && message.reaction !== undefined && (
                  <div className={classes.reactionsContainer}>
                    <AiFillLike
                      className={
                        message.reaction === 1
                          ? `${classes.reactionButton} ${classes.reactionActive}`
                          : classes.reactionButton
                      }
                      onClick={() =>
                        reactionHandler(message.id, message.sqlId, 1)
                      }
                      title="Tačan odgovor"
                    />
                    <AiFillDislike
                      className={
                        message.reaction === 0
                          ? `${classes.reactionButton} ${classes.reactionActive}`
                          : classes.reactionButton
                      }
                      onClick={() =>
                        reactionHandler(message.id, message.sqlId, 0)
                      }
                      title="Pogrešan odgovor"
                    />
                  </div>
                )}
              </span>
            ))}
            {answering && (
              <span className={classes.bot}>
                <ThreeDotsSpinner
                  className={classes.loadingBotMessage}
                  dotClassName={classes.loadingBotMessageDot}
                />
              </span>
            )}
          </div>

          <div className={classes.inputContainer}>
            <input
              type="text"
              placeholder="Unesite pitanje:"
              className={questionError ? classes.inputError : ""}
              onChange={questionChangeHandler}
              onKeyDown={questionKeyDownHandler}
              value={enteredQuestion}
            />
            <FiSend
              className={classes.sendButton}
              onClick={submitMessageHandler}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBot;
