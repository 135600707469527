import React from "react";
import classes from "./ThreeDotsSpinner.module.css";

const ThreeDotsSpinner = ({
  visible = true,
  className = "",
  dotClassName = "",
}) => {
  return (
    visible && (
      <div className={`${classes.container} ${className}`}>
        <div className={`${classes.dot1} ${dotClassName}`}></div>
        <div className={`${classes.dot2} ${dotClassName}`}></div>
        <div className={`${classes.dot3} ${dotClassName}`}></div>
      </div>
    )
  );
};

export default ThreeDotsSpinner;
