import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { contentActions } from "../store";

import classes from "./CreateNewPage.module.css";
import classes_2 from "./Page.module.css";

import Header from "../components/Header/Header";
import Button from "../components/UI/Button/Button";
import Loading from "../components/UI/Loading/Loading";
import Modal from "../components/UI/Modal/Modal";
import { EditText, EditTextarea } from "react-edit-text";

const CreateNewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enteredTitle, setEnteredTitle] = useState("");
  const [enteredContent, setEnteredContent] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [contentError, setContentError] = useState(false);

  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const titleSaveHandler = (text) => {
    setEnteredTitle(text.value);

    if (text.value.trim().length < 1) setTitleError(true);
    else setTitleError(false);
  };
  const contentSaveHandler = (text) => {
    setEnteredContent(text.value);

    if (text.value.trim().length < 1) setContentError(true);
    else setContentError(false);
  };

  const submitHandler = async () => {
    //check validations for empty inputs
    if (enteredTitle.trim().length < 1) return setTitleError(true);
    else if (enteredContent.trim().length < 1) return setContentError(true);

    try {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_BACKEND_URI}/content`, {
        title: enteredTitle,
        content: enteredContent,
      });
      dispatch(contentActions.forceUpdate(true));
      setLoading(false);
      setErrorMessage(`Uspješno ste kreirali novu stranicu '${enteredTitle}'.`);
      setError(false);
    } catch (err) {
      console.log(err);
      setErrorMessage(err.message);
      setError(true);
      setLoading(false);
    }
  };

  const modalCloseHandler = () => {
    setError(null);
    setErrorMessage("");
    navigate("/page/0");
  };

  return (
    <>
      {error === true && (
        <Modal
          title="Greška!"
          message={errorMessage}
          onClose={modalCloseHandler}
          buttonNoText="OK"
        />
      )}
      {error === false && (
        <Modal
          title="Uspješno!"
          type="success"
          message={errorMessage}
          onClose={modalCloseHandler}
          buttonNoText="OK"
        />
      )}

      {loading && <Loading alert={true} />}

      <Header />

      <h1 className={classes.test}>Kreiraj novu stranicu</h1>
      <div className={classes_2.container}>
        <EditText
          className={
            titleError
              ? `${classes_2.title} ${classes_2.inputError}`
              : classes_2.title
          }
          inputClassName={
            titleError
              ? `${classes_2.title} ${classes_2.inputError}`
              : classes_2.title
          }
          placeholder="Unesite naslov:"
          defaultValue={""}
          onSave={titleSaveHandler}
        />
        <EditTextarea
          className={
            contentError
              ? `${classes_2.content} ${classes_2.inputError}`
              : classes_2.content
          }
          inputClassName={
            contentError
              ? `${classes_2.content} ${classes_2.inputError}`
              : classes_2.content
          }
          placeholder="Unesite tekst:"
          defaultValue={""}
          onSave={contentSaveHandler}
          rows={20}
        />
        <div className={classes_2.buttonsFlex}>
          <Button title="Kreiraj" onClick={submitHandler} />
        </div>
      </div>
    </>
  );
};

export default CreateNewPage;
