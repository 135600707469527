import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { contentActions } from "../../store";
import { useNavigate } from "react-router-dom";

import classes from "./Header.module.css";
import { FaArrowCircleLeft, FaArrowCircleRight, FaHome } from "react-icons/fa";

const Header = () => {
  const currentPage = useSelector((state) => state.currentPage);
  const contentsLength = useSelector((state) => state.contents.length);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classesLeftArrow = [
    classes.icon,
    currentPage === "home" && classes.disabled,
  ];

  const classesRightArrow = [
    classes.icon,
    (currentPage >= contentsLength - 1 || contentsLength === undefined) &&
      classes.disabled,
  ];

  const nextPageHandler = () => {
    if (contentsLength === undefined) return;
    if (currentPage === "home") {
      dispatch(contentActions.nextPage());
      navigate(`/page/0`);
    } else if (currentPage < contentsLength - 1) {
      dispatch(contentActions.nextPage());
      navigate(`/page/${parseInt(currentPage) + 1}`);
    }
  };

  const prevPageHandler = () => {
    if (currentPage !== "home") {
      dispatch(contentActions.prevPage());
      navigate(`/page/${parseInt(currentPage) - 1}`);
    }
  };

  const homepageHandler = () => {
    navigate("/");
    dispatch(contentActions.setCurrentPage("home"));
  };

  return (
    <header className={classes.header}>
      <div
        className={`${classes.flex} ${classes.home}`}
        onClick={homepageHandler}
      >
        <FaHome className={classes.homeIcon} />
        <p>Home</p>
      </div>

      <div className={classes.flex}>
        <FaArrowCircleLeft
          className={classesLeftArrow.join(" ")}
          onClick={prevPageHandler}
        />
        <p>{`<< ${currentPage} >>`}</p>
        <FaArrowCircleRight
          className={classesRightArrow.join(" ")}
          onClick={nextPageHandler}
        />
      </div>
    </header>
  );
};

export default Header;
