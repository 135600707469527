import React from "react";

import classes from "./Loading.module.css";
import Backdrop from "../Backdrop/Backdrop";

const Loading = ({ backdropColor = "rgba(0, 0, 0, 0.7)", alert = false }) => {
  return (
    <Backdrop
      className={classes.loaderContainter}
      backgroundColor={backdropColor}
    >
      {alert && (
        <div className={classes.alertContainer}>
          <p>
            Obrada teksta u toku...{"\n"}
            Nemojte zatvarati ovaj proces!
          </p>
        </div>
      )}
      <div className={classes.loader}></div>
    </Backdrop>
  );
};

export default Loading;
