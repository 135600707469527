import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { contentActions } from "./store";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import Page from "./pages/Page";
import CreateNewPage from "./pages/CreateNewPage";

import Loading from "./components/UI/Loading/Loading";
import Modal from "./components/UI/Modal/Modal";

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const forceUpdate = useSelector((state) => state.forceUpdate);

  //fetch contents
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/content`
        );
        dispatch(contentActions.setContents(res.data));
        if (forceUpdate) dispatch(contentActions.forceUpdate(false));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err.message);
        console.log(err);
      }
    };
    fetchData();
  }, [dispatch, forceUpdate]);

  const modalCloseHandler = () => setError(false);

  return (
    <>
      {error && (
        <Modal
          title="Greška!"
          message={error}
          onClose={modalCloseHandler}
          buttonNoText="OK"
        />
      )}

      {loading ? (
        <Loading backdropColor="rgb(16, 19, 36)" />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route index element={<Home />} />
            <Route path="*" element={<Page404 />} />
            <Route path="page/:id" element={<Page />} />
            <Route path="create_new_page" element={<CreateNewPage />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
};

export default App;
