import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { contentActions } from "../store";
import { EditText, EditTextarea } from "react-edit-text";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import parse from "html-react-parser";

import classes from "./Page.module.css";
import Header from "../components/Header/Header";
import ChatBot from "../components/ChatBot/ChatBot";
import Button from "../components/UI/Button/Button";
import Loading from "../components/UI/Loading/Loading";
import Modal from "../components/UI/Modal/Modal";

const Home = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contents = useSelector((state) => state.contents);
  const currentPage = useSelector((state) => state.currentPage);

  const [enteredTitle, setEnteredTitle] = useState("");
  const [enteredContent, setEnteredContent] = useState("");
  const [initialTitle, setInitialTitle] = useState("");
  const [initialContent, setInitialContent] = useState("");
  const [jsonPhrase, setJsonPhrase] = useState("<p></p>");

  const [titleError, setTitleError] = useState(false);
  const [contentError, setContentError] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  //fill inputs with initial values
  useEffect(() => {
    if (contents[id] !== undefined) {
      setEnteredTitle(contents[id].title);
      setEnteredContent(contents[id].content);
      setInitialTitle(contents[id].title);
      setInitialContent(contents[id].content);
      const json = JSON.parse(contents[id].jsonPhrase);
      let phraseString = "";

      for (let i = 1; i < json.length; i++) {
        for (let j = 0; j < Object.keys(json[i]).length; j++) {
          if (j !== Object.keys(json[i]).length - 1) {
            phraseString += `<p><strong>F${j}</strong>: ${json[i][j]}</p>`;
          } else {
            phraseString += `<p><strong>rečenica:</strong> ${json[i]["recenica"]}<br></p>`;
            if (i < json.length - 1) phraseString += "<br>";
          }
        }
      }
      setJsonPhrase(phraseString);
    }
  }, [contents, id]);

  //check if the page exists
  useEffect(() => {
    if (contents !== 0 && contents[id] === undefined) {
      navigate("/");
      dispatch(contentActions.setCurrentPage("home"));
    } else dispatch(contentActions.setCurrentPage(id));
  }, [currentPage, contents, id, dispatch, navigate]);

  const titleSaveHandler = (text) => {
    if (contents[id] !== undefined) setEnteredTitle(text.value);

    if (text.value.trim().length < 1) setTitleError(true);
    else setTitleError(false);
  };

  const contentSaveHandler = (text) => {
    if (contents[id] !== undefined)
      if (contents[id] !== undefined) setEnteredContent(text.value);

    if (text.value.trim().length < 1) setContentError(true);
    else setContentError(false);
  };

  const submitHandler = async () => {
    //check validations for empty inputs
    if (enteredTitle.trim().length < 1) return setTitleError(true);
    else if (enteredContent.trim().length < 1) return setContentError(true);

    try {
      setLoading(true);
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URI}/content/${contents[id].id}`,
        {
          title: enteredTitle,
          content: enteredContent,
        }
      );
      setLoading(false);
      setErrorMessage(`Uspješno ste uredili stranicu '${enteredTitle}'.`);
      setError(false);
    } catch (err) {
      console.log(err);
      setErrorMessage(err.message);
      setError(true);
      setLoading(false);
    }
  };

  const modalCloseHandler = () => {
    setError(null);
    setErrorMessage("");
    navigate(0);
  };

  const deleteHandler = () => setDeleteModal(true);

  const deleteModalCloseHandler = () => setDeleteModal(false);

  const confirmDeleteHandler = async () => {
    setDeleteModal(false);
    try {
      setLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URI}/content/${contents[id].id}`
      );
      setLoading(false);
      setErrorMessage(`Uspješno ste obrisali stranicu '${initialTitle}'.`);
      setError(false);
    } catch (err) {
      console.log(err);
      setErrorMessage(err.message);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <>
      {error === true && (
        <Modal
          title="Greška!"
          message={errorMessage}
          onClose={modalCloseHandler}
          buttonNoText="OK"
        />
      )}
      {error === false && (
        <Modal
          title="Uspješno!"
          type="success"
          message={errorMessage}
          onClose={modalCloseHandler}
          buttonNoText="OK"
        />
      )}

      {deleteModal && (
        <Modal
          title="Upozorenje!"
          type="warning"
          message={`Da li ste sigurni da želite obrisati stranicu '${initialTitle}' ?`}
          onConfirm={confirmDeleteHandler}
          onClose={deleteModalCloseHandler}
          buttonYesText="Obriši"
          buttonNoText="Izlaz"
        />
      )}

      {loading && <Loading />}

      {contents !== 0 && contents[id] !== undefined && (
        <>
          <Header />
          <ChatBot />
          <div className={classes.container}>
            <EditText
              className={
                titleError
                  ? `${classes.title} ${classes.inputError}`
                  : classes.title
              }
              inputClassName={
                titleError
                  ? `${classes.title} ${classes.inputError}`
                  : classes.title
              }
              placeholder="Unesite naslov:"
              defaultValue={contents[id].title}
              onSave={titleSaveHandler}
            />
            <EditTextarea
              className={
                contentError
                  ? `${classes.content} ${classes.inputError}`
                  : classes.content
              }
              inputClassName={
                contentError
                  ? `${classes.content} ${classes.inputError}`
                  : classes.content
              }
              placeholder="Unesite tekst:"
              defaultValue={contents[id].content}
              onSave={contentSaveHandler}
              rows={20}
            />
            <div className={classes.buttonsFlex}>
              {(enteredTitle !== initialTitle ||
                enteredContent !== initialContent) && (
                <Button title="Uredi" onClick={submitHandler} />
              )}
              <Button
                title="Obriši"
                className={classes.delete}
                onClick={deleteHandler}
              />
            </div>

            <div className={classes.phraseHeader}>Fraze</div>
            <div className={classes.phraseContainer}>{parse(jsonPhrase)}</div>
            <div className={classes.legend}>
              <span className="subject">SUBJEKAT</span>
              <span className="verb">PREDIKAT</span>
              <span className="root">KORIJEN</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
